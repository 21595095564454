const routes = [
  {
    path: "/healthHouse/menu",
    name: "healthHouse-menu",
    meta: {
      title: "体检菜单",
      from: "healthHouse-menu",
    },
    component: (resolve) =>
      require(["@/views/healthHouse/menu/index.vue"], resolve),
  },
  {
    path: "/healthHouse/my",
    name: "healthHouse-my",
    meta: {
      title: "个人信息",
      from: "healthHouse",
    },
    component: (resolve) =>
      require(["@/views/healthHouse/my/index.vue"], resolve),
  },
  {
    path: "/healthHouse/myInfo",
    name: "healthHouse-myInfo",
    meta: {
      title: "我的信息",
      from: "healthHouse",
    },
    component: (resolve) =>
      require(["@/views/healthHouse/my/myInfo.vue"], resolve),
  },
  {
    path: "/healthHouse/healthReport",
    name: "healthReport",
    meta: {
      title: "健康小屋报告",
      from: "healthHouse",
    },
    component: (resolve) =>
      require(["@/views/healthHouse/healthReport/index.vue"], resolve),
  },
  {
    path: "/healthHouse/bloodPressureReport",
    name: "bloodPressureReport",
    meta: {
      title: "血压报告",
      from: "healthHouse",
    },
    component: (resolve) =>
      require(["@/views/healthHouse/healthReport/bloodPressureReport.vue"], resolve),
  },
];

export default routes;
