import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    communityId: "",
    userId: "",
    houseId: "",
    defOrgId: "",
    tenantId: "",
    openId: "",
    assetId: "",
    userInfo: {},
    device: {
      1: {
        deviceType: 1,
        testProName: "身高体重",
      },
      2: {
        deviceType: 2,
        testProName: "人体成分",
      },
      3: {
        deviceType: 3,
        testProName: "骨密度",
      },
      4: {
        deviceType: 4,
        testProName: "动脉硬化血压",
      },
    },
  },
  mutations: {
    setUserInfo: (state, value) => {
      state.userInfo = value;
    },
    setCommunityId(state, communityId) {
      state.communityId = communityId;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setHouseId(state, houseId) {
      state.houseId = houseId;
    },
    setDefOrgId(state, defOrgId) {
      state.defOrgId = defOrgId;
    },
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
    },
    setOpenId(state, openId) {
      state.openId = openId;
    },
    setAssetId(state, assetId) {
      state.assetId = assetId;
    },
  },
  actions: {},
  modules: {},
});
